/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from "react"
import PropTypes from "prop-types"
import { Flex, Box, Image, Text, jsx } from "theme-ui"
import Modal from "../modal/modal"
import ButtonLink from "../button-link/button-link"

// DEVELOPER NOTE:
// This hero component is for the Fine Wire landing pages with calls-to-action in the hero.

const Hero = ({
  heroWide,
  bkImgDesktop,
  bkImgMobile,
  bkColorLeft,
  bkColorRight,
  children,
}) => {
  return (
    <>
      <div
        sx={{
          mx: "auto",
          maxWidth: heroWide ? "100%" : "contentWidth",
          background: heroWide
            ? [
                (theme) => `${theme.colors.goreLightestGray}`,
                null,
                `linear-gradient(to right, ${bkColorLeft} 0%, ${bkColorLeft} 50%, ${bkColorRight} 50%, ${bkColorRight} 100%)`,
              ]
            : [
                (theme) => `${theme.colors.goreLightestGray}`,
                null,
                bkColorLeft,
              ],
        }}
      >
        <div
          sx={{
            mx: "auto",
            maxWidth: heroWide ? "heroWidth" : "contentWidth",
            backgroundImage: [null, null, `url(${bkImgDesktop})`],
            backgroundSize: heroWide ? "contain" : "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "right center",
          }}
        >
          <Flex sx={{ display: ["block", null, "none"] }}>
            <Image src={bkImgMobile} />
          </Flex>
          <Flex
            sx={{
              flexWrap: "wrap",
              maxWidth: "containerWidth",
              mx: "auto",
              px: "defaultPadding",
            }}
          >
            <Box
              sx={{
                width: heroWide
                  ? ["100%", "90%", "60%"]
                  : ["100%", "100%", "50%"],
                flex: "0 0 auto",
                my: heroWide
                  ? ["defaultPadding", null, "heroWideYMargin"]
                  : ["defaultPadding", null, "heroNormalYMargin"],
                "span > *:first-child": {
                  mt: 0,
                },
                "span > *:last-child": {
                  mb: 0,
                },
                // background: "lime",
              }}
            >
              {children}
              <Flex
                mx="auto"
                mt="1rem"
                sx={{
                  maxWidth: "containerWidth",
                  justifyContent: "flex-start",
                  flexWrap: "wrap",
                }}
              >
                <Box mt="1rem" mb="1rem" sx={{ flex: "0 0 auto" }}>
                  <ButtonLink
                    buttonStyle="accented"
                    buttonIcon="arrow"
                    buttonDestination="https://chamfr.com/product-category/wire/?filter_seller=902&utm_source=sellers&utm_medium=Gore&utm_campaign=fw_website"
                  >
                    Order Now
                  </ButtonLink>
                </Box>
                <Box mt="1rem" mb="1rem" sx={{ flex: "0 0 auto" }}>
                  <Modal
                    trigger={
                      <ButtonLink buttonStyle="accented" buttonIcon="document">
                        White Paper
                      </ButtonLink>
                    }
                    modalContent={
                      <Box sx={{ pt: "defaultPadding" }}>
                        <Text
                          variant="modalTitle"
                          sx={{
                            width: 300,
                            mx: "auto",
                            pt: "defaultPadding",
                          }}
                        >
                          Get the white paper
                        </Text>
                        <iframe
                          src="https://gore.formstack.com/forms/gil_fine_wire"
                          title="GORE INNOVATION LABS Fine Wire"
                          width="300"
                          height="480"
                          frameBorder="0"
                          sx={{
                            mx: "auto",
                            pt: "defaultPadding",
                            display: "block",
                          }}
                        />
                      </Box>
                    }
                  />
                </Box>
              </Flex>
            </Box>
          </Flex>
        </div>
      </div>
    </>
  )
}

Hero.propTypes = {
  heroWide: PropTypes.bool,
  bkImgDesktop: PropTypes.string.isRequired,
  bkImgMobile: PropTypes.string.isRequired,
  bkColorLeft: PropTypes.string.isRequired,
  bkColorRight: PropTypes.string.isRequired,
  // wayfinderBadge: PropTypes.string,
  // heroCaption: PropTypes.string,
  children: PropTypes.node.isRequired,
}

Hero.defaultProps = {
  heroWide: false,
  // wayfinderBadge: null,
  // heroCaption: null,
}

export default Hero
