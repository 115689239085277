/* eslint-disable react/jsx-props-no-spreading */
/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
// import { Flex, Box, Text, BaseStyles, jsx } from "theme-ui"
import { Flex, Box, BaseStyles, Text, jsx } from "theme-ui"
import Layout from "../components/layout/layout"
import Metadata from "../components/metadata/metadata"
import MarkdownConverter from "../components/markdown-converter/markdown-converter"
import Hero from "../components/hero/hero-rev"
import Modal from "../components/modal/modal"
import ButtonLink from "../components/button-link/button-link"

const LandingPageBody = ({ contentBlocks }) => (
  <>
    {contentBlocks.map(({ blockGroupType, blockCells }) => (
      <>
        {blockGroupType === "Wide Hero" && (
          <>
            {blockCells.map(
              ({
                bkColorLeft,
                bkColorRight,
                bkImgDesktop,
                bkImgMobile,
                copy,
              }) => (
                <Hero
                  heroWide
                  bkColorLeft={bkColorLeft}
                  bkColorRight={bkColorRight}
                  bkImgDesktop={bkImgDesktop}
                  bkImgMobile={bkImgMobile}
                >
                  <MarkdownConverter content={copy} />
                </Hero>
              )
            )}
          </>
        )}
        {blockGroupType === "Normal Row" && (
          <Flex
            sx={{
              maxWidth: "containerWidth",
              mx: "auto",
              mt: "2rem",
            }}
          >
            {blockCells.map(({ blockCellType, copy }) => (
              <>
                {blockCellType === "Normal Column" && (
                  <Box
                    px="defaultPadding"
                    sx={{ width: ["100%", "90%", "80%", "80%"] }}
                  >
                    <MarkdownConverter content={copy} />
                  </Box>
                )}
              </>
            ))}
          </Flex>
        )}
      </>
    ))}
    <Flex
      mx="auto"
      pl="defaultPadding"
      mb="3rem"
      sx={{
        maxWidth: "containerWidth",
        justifyContent: "flex-start",
        flexWrap: "wrap",
      }}
    >
      <Box mt="1rem" mb="1rem" sx={{ flex: "0 0 auto" }}>
        <ButtonLink
          buttonStyle="accented"
          buttonIcon="arrow"
          buttonDestination="https://chamfr.com/product-category/wire/?filter_seller=902&utm_source=sellers&utm_medium=Gore&utm_campaign=fw_website"
        >
          Order Now
        </ButtonLink>
      </Box>
      <Box mt="1rem" mb="1rem" sx={{ flex: "0 0 auto" }}>
        <Modal
          trigger={
            <ButtonLink buttonStyle="accented" buttonIcon="document">
              White Paper
            </ButtonLink>
          }
          modalContent={
            <Box sx={{ pt: "defaultPadding" }}>
              <Text
                variant="modalTitle"
                sx={{
                  width: 300,
                  mx: "auto",
                  pt: "defaultPadding",
                }}
              >
                Get the white paper
              </Text>
              <iframe
                src="https://gore.formstack.com/forms/gil_fine_wire"
                title="GORE INNOVATION LABS Fine Wire"
                width="300"
                height="480"
                frameBorder="0"
                sx={{
                  mx: "auto",
                  pt: "defaultPadding",
                  display: "block",
                }}
              />
            </Box>
          }
        />
      </Box>
    </Flex>
  </>
)

LandingPageBody.propTypes = {
  contentBlocks: PropTypes.arrayOf(PropTypes.object).isRequired,
}

const LandingPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <Metadata title={post.frontmatter.title} article />
      <BaseStyles>
        <LandingPageBody contentBlocks={post.frontmatter.contentBlocks} />
      </BaseStyles>
    </Layout>
  )
}

LandingPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }).isRequired,
}

export default LandingPage

export const query = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        path
        contentBlocks {
          blockGroup
          blockGroupType
          blockCells {
            blockCell
            blockCellType
            bkColorLeft
            bkColorRight
            bkImgDesktop
            bkImgMobile
            copy
          }
        }
      }
    }
  }
`
